<div class="container-information-sidebar" *ngIf="!state.loading">
  <div class="card">
    <div class="row-sidebar-layout" [class.sidebar-expanded]="expanded && currentTab">
      <div class="col-tab-indicators">
        <!-- <div class="sidebar-icon sidebar-collapse-toggle" [class.sidebar-collapse-toggle-expanded]="expanded" (click)="toggleExpanded()">
          <span class="fal fa-fw fa-chevron-left"></span>
        </div> -->

        <ng-container *ngFor="let tabType of tabsShown;">
          <ng-container *ngIf="tabData[tabType]">
            <div class="sidebar-icon" [class.sidebar-icon-active]="expanded && (currentTab === tabType)" (click)="openTab(tabType)" [tooltip]="((expanded && currentTab === tabType) ? 'Collapse ' : 'Expand ') + tabData[tabType].title" placement="left" [triggers]="globals.tooltipTriggers">
              <span class="fal fa-fw" [ngClass]="tabData[tabType].icon"></span>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="col-tab-contents">
        <ng-container *ngIf="expanded && currentTab && tabData[currentTab]">
          <div class="tab-contents-inner" [style.width]="tabData[currentTab].width || defaultContentWidth">
            <div class="content">
              <h4 class="standard-heading" *ngIf="tabData[currentTab].title">{{tabData[currentTab].title}}</h4>
              <div class="standard-description" *ngIf="tabData[currentTab].description">{{tabData[currentTab].description}}</div>
            </div>

            <div class="sidebar-divider-h"></div>

            <ng-container [ngSwitch]="currentTab">
              <ng-container *ngSwitchCase="eInformationSidebarTab.COMPETENCIES">
                <app-information-sidebar-competencies [userIds]="userIds" [userIdsManaging]="userIdsManaging"></app-information-sidebar-competencies>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.ONE_TO_ONES">
                <app-information-sidebar-one-to-ones [userIds]="userIds"></app-information-sidebar-one-to-ones>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.FEEDBACK_UNPROMPTED">
                <app-information-sidebar-feedback-unprompted [userIds]="userIds"></app-information-sidebar-feedback-unprompted>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.FEEDBACK_IDEAS">
                <app-information-sidebar-feedback-ideas [userIds]="userIds"></app-information-sidebar-feedback-ideas>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.FEEDBACK_PRAISE">
                <app-information-sidebar-feedback-praise [userIds]="userIds"></app-information-sidebar-feedback-praise>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.FEEDBACK_PEER">
                <app-information-sidebar-feedback-peer [userIds]="userIds"></app-information-sidebar-feedback-peer>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.FEEDBACK_REQUESTS">
                <app-information-sidebar-feedback-requests [userIds]="userIds"></app-information-sidebar-feedback-requests>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.FEEDBACK_EXTERNAL">
                <app-information-sidebar-feedback-external [userIds]="userIds"></app-information-sidebar-feedback-external>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.REVIEWS">
                <app-information-sidebar-reviews [userIds]="userIds" [userIdsManaging]="userIdsManaging" [cycleId]="cycleId"></app-information-sidebar-reviews>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.VINCERE">
                <app-information-sidebar-vincere [userIds]="userIds" [userIdsManaging]="userIdsManaging"></app-information-sidebar-vincere>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.TASKS">
                <app-information-sidebar-tasks [userIds]="userIds"></app-information-sidebar-tasks>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.QUALIFICATIONS">
                <app-information-sidebar-qualifications [userIds]="userIds"></app-information-sidebar-qualifications>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.SKILLS">
                <app-information-sidebar-skills [userIds]="userIds" [userIdsManaging]="userIdsManaging"></app-information-sidebar-skills>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.GOALS">
                <app-information-sidebar-goals [userIds]="userIds" [meetingPrevious]="meetingPrevious" [meetingCurrent]="meetingCurrent" [publicGoalsOnly]="publicGoalsOnly"></app-information-sidebar-goals>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.ZENDESK">
                <app-information-sidebar-zendesk [userIds]="userIds"></app-information-sidebar-zendesk>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.EVALUAGENT">
                <app-information-sidebar-evaluagent [userIds]="userIds"></app-information-sidebar-evaluagent>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.ABSORBLMS">
                <app-information-sidebar-absorblms [userIds]="userIds"></app-information-sidebar-absorblms>
              </ng-container>

              <!-- Meetings only tabs (Require a schedule to be passed in) -->
              <ng-container *ngSwitchCase="eInformationSidebarTab.SCHEDULE_CALENDAR">
                <app-information-sidebar-schedule-calendar [schedule]="schedule" [meetingNavigationControl]="meetingNavigationControl"></app-information-sidebar-schedule-calendar>
              </ng-container>

              <ng-container *ngSwitchCase="eInformationSidebarTab.SENTIMENT_SCALE_TIMELINE">
                <app-information-sidebar-sentiment-scale-timeline [schedule]="schedule"></app-information-sidebar-sentiment-scale-timeline>
              </ng-container>

              <!-- Not used -->
              <ng-container *ngSwitchCase="eInformationSidebarTab.ACTION_POINTS">
                <app-information-sidebar-action-points [userIds]="userIds" [schedule]="schedule" [meetingId]="meetingNavigationControl.value"></app-information-sidebar-action-points>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>